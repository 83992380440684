<template>
    <div class="loading-screen">
        <div class="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>
<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
}

.loading-screen {
    width: 100%;
    height: 100vh;
    background-color: #0909098c;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    top: 0;
    left: 0;
}

.loading {
    width: 80px;
    display: flex;
    flex-wrap: wrap;
    animation: rotate 3s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.loading span {
    width: 32px;
    height: 32px;
    background-color: #0069F7;
    margin: 3px;
    animation: scale 1.5s linear infinite;
}

@keyframes scale {
    50% {
        transform: scale(1.2);
    }
}

.loading span:nth-child(1) {
    border-radius: 50% 50% 0 50%;
    background-color: #007D88;
    transform-origin: bottom right;
}

.loading span:nth-child(2) {
    border-radius: 50% 50% 50% 0;
    background-color: #00B0FF;
    transform-origin: bottom left;
    animation-delay: .5s;
}

.loading span:nth-child(3) {
    border-radius: 50% 0 50% 50%;
    background-color: #18DDEF;
    transform-origin: top right;
    animation-delay: 1.5s;
}

.loading span:nth-child(4) {
    border-radius: 0 50% 50% 50%;
    background-color: #81E230;
    transform-origin: top left;
    animation-delay: 1s;
}
</style>